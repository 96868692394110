import { EyeIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import Input from '../../components/forms/Input/Input';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import Table from '../../components/Table/Table';
import {
  deleteApplication,
  getApplications,
} from '../../services/application/application';
import ExportExcel from './ExportExcel';
import Select from '../../components/forms/Select/Select';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import ExportPasses from './ExportPasses';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid';
import { getExamDates } from '../../services/core/coreService';
import { getDistricts } from '../../services/catalog/catalog';
import { UserContext } from '../../utils/contexts/UserContext';

export default function Students() {
  const navigate = useNavigate();
  const { examYear } = useContext(ExamYearContext);
  const { user } = useContext(UserContext);

  const [students, setStudents] = useState([]);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [iinToSearch, setIinToSearch] = useState('');
  const [aspFilter, setAspFilter] = useState(null);
  const [isGuestFilter, setIsGuestFilter] = useState(null);
  const [passesModalOpen, setPassesModalOpen] = useState(false);
  const [examCenters, setExamCenters] = useState([]);
  const [examDates, setExamDates] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'index',
        accessor: (_row, i) => i + 1,
        // Cell: (props) => {
        //   return <span>{props.state.pageIndex}</span>;
        // },
      },
      {
        Header: 'ИИН',
        accessor: 'iin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
        Cell: (props) => (
          <>
            {props.row.original.school.uuid !==
              props.row.original.exam_school.uuid && (
              <span className='bg-orange-400 font-semibold'>{props.value}</span>
            )}
            {props.row.original.school.uuid ===
              props.row.original.exam_school.uuid && (
              <span className=''>{props.value}</span>
            )}
          </>
        ),
      },
      {
        Header: 'Имя',
        accessor: 'first_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'Фамилия',
        accessor: 'last_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: 'Район',
        accessor: 'district.name',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <>
            {props.row.original.school.uuid !==
              props.row.original.exam_school.uuid && (
              <span className='bg-orange-400 font-semibold'>{props.value}</span>
            )}
            {props.row.original.school.uuid ===
              props.row.original.exam_school.uuid && (
              <span className=''>{props.value}</span>
            )}
          </>
        ),
      },
      {
        Header: 'День тестирования',
        accessor: 'exam',
        id: 'exam_day',
        // Filter: SelectColumnFilter,
        Cell: ({ value }) => {
          return value?.name ? value.name?.substr(8, 10) : '-';
        },
      },
      {
        Header: 'Время тестирования',
        accessor: 'exam',
        id: 'exam_time',
        // Filter: SelectColumnFilter,
        Cell: ({ value }) => {
          return value?.name ? value.name?.substr(0, 5) : '-';
        },
      },
      {
        Header: 'Дата регистрации',
        accessor: 'created',
        // Filter: SelectColumnFilter,
        Cell: (props) => {
          const custom_date = moment(props.value).format('DD.MM.yyyy');
          return <span>{custom_date}</span>;
        },
      },
      {
        Header: 'Экзаменационная школа',
        accessor: 'exam_school.name',
        Cell: (props) => (
          <>
            {props.row.original.school.uuid !==
              props.row.original.exam_school.uuid && (
              <span className='bg-orange-400 font-semibold'>{props.value}</span>
            )}
            {props.row.original.school.uuid ===
              props.row.original.exam_school.uuid && (
              <span className=''>{props.value}</span>
            )}
          </>
        ),
      },
      {
        Header: 'Экзаменационный центр',
        accessor: 'exam_center.name',
        // Filter: SelectColumnFilter,
      },
      {
        Header: 'PDF отправлен?',
        accessor: 'sent',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <>
            {props.value ? (
              <span className='rounded-md px-2 py-1 font-bold text-green-600'>
                Да
              </span>
            ) : (
              <span className='rounded-md px-2 py-1 font-bold text-red-600'>
                Нет
              </span>
            )}
          </>
        ),
      },
      {
        Header: 'Тел номер',
        accessor: 'parent_phone_number',
      },
      {
        Header: 'ӘОТ',
        accessor: 'asp_status',
        Cell: (props) => (
          <>
            {props.value === '0' && <span className=''>Нет</span>}
            {props.value === '1' && <span className=''>Да, не одобрено</span>}
            {props.value === '2' && <span className=''>Да, одобрено</span>}
          </>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        fixed: 'right',
        Cell: (props) => (
          <div className='flex gap-2'>
            <Button
              Icon={EyeIcon}
              onClick={() => {
                navigate(`${props.row.original.uuid}`, {
                  state: {
                    socialDocType: props.row.original.social_doc_type,
                  },
                });
              }}
            />
            <Button
              disabled={
                moment(props.row.original.created).year() !== moment().year()
              }
              Icon={TrashIcon}
              color='error'
              onClick={() => {
                setModalOpen(true);
                setStudentToDelete(props.row.original);
              }}
            />
          </div>
        ),
      },
    ],
    [navigate],
  );

  useEffect(() => {
    if (examYear?.uuid) {
      setLoading(true);

      getApplications({
        exam_year: examYear?.uuid,
        is_approved: true,
        page: 1,
        page_size: 50,
        ordering: '-created',
      })
        .then((res) => {
          setStudents(res.results);
          setCount(res.count);
          setPageCount(Math.ceil(res.count / 50));
        })
        .finally(() => {
          setLoading(false);
        });

      getExamDates(examYear.uuid).then((res) => {
        var options = res?.results?.map((date) => ({
          label: date.name,
          value: date.uuid,
        }));
        setExamDates(options);
      });
    }

    return () => {
      setExamDates([]);
    };
  }, [examYear]);

  useEffect(() => {
    if (user?.school?.uuid) {
      getDistricts({ school: user.school.uuid }).then((res) => {
        const centerOptions = res.results
          ?.filter((d) => d?.is_exam_center)
          .map((item) => ({
            label: item.name,
            value: item.uuid,
          }));
        setExamCenters(centerOptions);
      });
    }

    return () => {
      setExamCenters([]);
    };
  }, [user]);

  const nextPage = () => {
    setLoading(true);

    getApplications({
      exam_year: examYear?.uuid,
      is_approved: true,
      page: page + 1,
      page_size: pageSize,
      name: iinToSearch,
    })
      .then((res) => {
        setStudents(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getApplications({
      exam_year: examYear?.uuid,
      is_approved: true,
      page: page - 1,
      page_size: pageSize,
      name: iinToSearch,
    })
      .then((res) => {
        setStudents(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getApplications({
      exam_year: examYear?.uuid,
      is_approved: true,
      page: pageNum,
      page_size: pageSize,
    })
      .then((res) => {
        setStudents(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(pageNum);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row gap-2'>
          <Input
            type='text'
            className='w-72'
            placeholder='Поиск по ИИН, имени и фамилии'
            value={iinToSearch}
            onChange={(e, d) => {
              setIinToSearch(e.target.value);
            }}
          />
          <Button
            type='submit'
            text='Поиск'
            color='primary'
            className='h-9'
            onClick={() => {
              setLoading(true);
              getApplications({
                exam_year: examYear?.uuid,
                is_approved: true,
                page_size: pageSize,
                name: iinToSearch,
              })
                .then((res) => {
                  setStudents(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          />
          <Button
            type='button'
            Icon={XMarkIcon}
            className='h-9'
            onClick={() => {
              setLoading(true);
              getApplications({
                exam_year: examYear?.uuid,
                is_approved: true,
                page_size: pageSize,
              })
                .then((res) => {
                  setStudents(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setIinToSearch('');
                  setLoading(false);
                });
            }}
          />
        </div>
        <div className='flex flex-row gap-2'>
          <Button
            type='button'
            text='Пропуски'
            color='primary-solid'
            Icon={ClipboardDocumentCheckIcon}
            onClick={() => {
              setPassesModalOpen(true);
            }}
          />
          <ExportPasses
            modalOpen={passesModalOpen}
            setModalOpen={setPassesModalOpen}
            examCenters={examCenters}
            examDates={examDates}
          />
          <ExportExcel />
        </div>
      </div>
      <div className='mt-2 flex flex-row gap-4'>
        <div>
          <Select
            label='ӘОТ статус'
            className='w-72'
            isSearchable={false}
            value={aspFilter}
            options={[
              {
                label: 'Все кандидаты',
                value: null,
              },
              {
                label: 'Нет',
                value: 0,
              },
              {
                label: 'Да, не одобрено',
                value: '1',
              },
              {
                label: 'Да, одобрено',
                value: '2',
              },
            ]}
            onChange={(e) => {
              setLoading(true);
              getApplications({
                exam_year: examYear?.uuid,
                is_approved: true,
                page_size: pageSize,
                asp_status: e.value,
              })
                .then((res) => {
                  setStudents(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                });
              setAspFilter(e.value);
            }}
          />
        </div>
        <div>
          <Select
            label='Эказамен в другой школе'
            className='w-72'
            isSearchable={false}
            value={isGuestFilter}
            options={[
              {
                label: 'Все кандидаты',
                value: null,
              },
              {
                label: 'Да',
                value: true,
              },
            ]}
            onChange={(e) => {
              setLoading(true);
              getApplications({
                exam_year: examYear?.uuid,
                is_approved: true,
                page_size: pageSize,
                is_guest: e.value,
              })
                .then((res) => {
                  setStudents(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                });
              setIsGuestFilter(e.value);
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={students}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
      />
      <CustomDialog
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
        headerText={'Удалить'}
      >
        <div className='flex flex-col gap-4'>
          <p>
            Вы уверены, что хотите удалить ученика{' '}
            <b>
              {studentToDelete?.first_name + ' ' + studentToDelete?.last_name}
            </b>
            ?
          </p>
          <Button
            type='button'
            color='error'
            text='Удалить'
            onClick={() => {
              deleteApplication(studentToDelete?.uuid).then(() => {
                getApplications({
                  exam_year: examYear?.uuid,
                  is_approved: true,
                })
                  .then((res) => {
                    setStudents(res.results);
                  })
                  .finally(() => {
                    setModalOpen(false);
                  });
              });
            }}
          />
        </div>
      </CustomDialog>
    </div>
  );
}
